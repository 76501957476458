.loader
    position fixed
    z-index 100000
    top 0
    left 0
    height 100vh
    // height calc(var(--vh, 1vh) * 100)
    width 100vw
    display flex
    flex-direction column
    justify-content center
    align-items center
    background var(--midnight)
    pointer-events all


.loader__illustration
    background url("~/static/img/loading-animation.svg") no-repeat center center
    background-size contain
    width 100vw
    height calc(100vw * 1.22)
    display block
    position absolute
    transform translateX(-50%)
    left 50%
    top 0
    @media(min-width $tablet)
        width 70vw
        height calc(70vw * 1.22)
    @media(min-width $laptop)
        width 32.5vw
        height calc(32.5vw * 1.22)
    @media(min-width $large-desktop)
        width 22.5vw
        height calc(22.5w * 1.22)

.loader__text
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 80%
    text-transform uppercase
    font-size 12px
    @media(min-width $tablet)
        font-size 16px


.loader__text span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.loader__text span:nth-child(1) {
    margin-left .5em
}
.loader__text span:nth-child(2) {
    animation-delay: .2s;
}
.loader__text span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blinkdot {
    0% { opacity: .2; }
    20% { opacity: 1; }
    100% { opacity: .2; }
}

.loaded
    .loader
        opacity 0
        pointer-events none