.nav
    position fixed
    width 100vw
    // height calc(var(--vh, 1vh) * 100)
    height 100vh
    z-index 100
    padding var(--nav-vpad) var(--nav-hpad)
    pointer-events none

.nav--open
    main
        pointer-events none
        opacity 0
        section
            pointer-events none
    .nav
        pointer-events all
        z-index 100000
    .nav__accordion
        pointer-events all
        opacity 1
        visibility visible
    .nav__item
        pointer-events all
    .nav__cta
        .button--hub
            display inline-block
    .nav__share, .nav__copyright
        color var(--main-color)
        @media(max-width $tablet)
            display none
    .modal--scene
        opacity 0
    .nav__mobile-controls
        opacity 0
    .videos
        opacity 0!important
    .animation
        opacity 0.2!important
    .nav__catch--video
        opacity 0
        pointer-events none
    
.nav--open[data-active-color="red"]
    .nav__logo
        background url("~/static/img/logo-red.svg") no-repeat center center
        background-size contain
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-red.svg') no-repeat center center
        &:hover
            p
                &:before
                    background url('~/static/img/share-orange.svg') no-repeat center center

.nav--open[data-active-color="blue"]
    .nav__logo
        background url("~/static/img/logo-blue.svg") no-repeat center center
        background-size contain
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-blue.svg') no-repeat center center
        &:hover
            p
                &:before
                    background url('~/static/img/share-icon-blue.svg') no-repeat center center


.nav__accordion
    position absolute
    z-index 20
    top 55%
    transform translateY(-50%)
    left var(--nav-hpad)
    flex-direction column
    display flex
    width calc(100vw - calc(2 * var(--nav-hpad)))
    max-height calc(100vh - 160px)
    overflow-y scroll
    opacity 0
    pointer-events none
    visibility hidden
    &::-webkit-scrollbar
        width 0
    @media(min-width $tablet)
        top 50%
        display flex
    @media(min-width $laptop)
        overflow hidden
        width 15vw

.is-scrolled
    .nav__accordion
        @media(min-width $laptop)
            opacity 1
            pointer-events all
    .animation
        opacity 1
        transition(opacity .5s ease)

.nav__progress
    margin 8px 0
    height 1px
    background var(--main-color)
    opacity 1
    width 100%
    position relative
    overflow hidden
    display none
    @media(min-width $laptop)
        display block

.nav__hamburger
    @media(min-width $laptop)
        display none

.nav__catch
    z-index 1
    width 100vw
    // height 100vh
    height calc(var(--vh, 1vh) * 100)
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 50%
    pointer-events all
    display none
    &--modal
        z-index 3

.nav__annotation
    position absolute
    transform translateY(-50%)
    left 32px
    top 50%
    font-size 10px
    display inline-block
    white-space nowrap
    text-transform uppercase
    opacity 0

.nav__title
    display flex
    flex-direction row
    align-items center
    justify-content flex-start
    padding 0.8em 1em
    margin 0
    @media(min-width $laptop)
        padding 0
    h3
        font-size 28px
        line-height 28px
        padding 0
        color var(--cream)
        margin-right 8px
        font-family var(--boldFont)
        @media(min-width $laptop)
            font-size 20px
            color var(--main-color)
        @media(min-width $large-desktop)
            margin-right 16px
    h4
        font-size 10px
        line-height 10px
        vertical-align top
        text-transform uppercase
        @media(min-width $laptop)
            font-size 10px
        @media(min-width $large-desktop)
            font-size 12px

.nav__progress-bar
    position absolute
    background var(--main-color)
    height 100%
    width 100%

.nav__progress--mobile
    display block
    position absolute
    bottom 0
    left 0
    background rgba(61,76,233, 0.2)
    height 8px
    margin 0
    z-index 2
    @media(min-width $laptop)
        display none
    .nav__progress-bar
        opacity 0
        &.is-active
            opacity 1

.nav__item
    opacity 1
    cursor pointer
    display inline-block
    border-bottom 1px solid var(--main-color)
    pointer-events none
    @media(min-width $laptop)
        pointer-events all
        border-bottom 0
        opacity 0.2
        transition opacity 0.5s ease
        position relative
        &:hover
            opacity 1
            .nav__annotation
                opacity 1
            .nav__progress
                background var(--white)
                width 24px
                transition width 0.5s ease
    .nav__title
        @media(min-width $laptop)
            height 0
            width 16px
            transition all 0.5s ease
            overflow hidden
    .nav__progress
        width 16px
        transition width 0.5 ease
    &.active
        .nav__annotation 
            display none
        .nav__title
            height auto
            width auto
            transition all 0.5s ease
            @media(max-width $laptop)
                background rgba(61, 76, 233, 0.4)
        .nav__progress
            height 4px
            width auto
            transition width 0.5 ease
            background var(--white)
            opacity 1
            @media(min-width $laptop)
                width 120px
            @media(min-width $large-desktop)
                width 160px
        opacity 1
        transition opacity 0.5s ease

.nav__logo
    background url("~/static/img/logo.svg") no-repeat center center
    background-size contain
    width 56px
    height 54.4px
    display inline-block
    position absolute
    top var(--nav-vpad)
    left var(--nav-hpad)
    z-index 2
    @media(min-width $tablet)
        width 106px
        height 103px

.button.button--hub
    color var(--midnight)
    font-weight 500

.nav__cta
    position absolute
    top var(--nav-vpad)
    right var(--nav-hpad)
    display flex
    flex-direction row
    z-index 100
    .button--hub
        display none
        @media(min-width $laptop)
            display inline-block

.nav__socials
    margin-left 0

.nav__icon
    height 32px
    width 32px
    display inline-block
    border 1px solid var(--main-color)
    margin 12px
    margin-left 0
    border-radius 4px
    cursor pointer
    pointer-events all
    position relative
    overflow hidden
    .icon
        transition-delay 0.1s
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        width 100%
        height 100%
        z-index 3
    @media(min-width $tablet)
        height 40px
        width 40px
        margin-right 16px
    &--facebook
        .icon
            background url('~/static/img/facebook-black.svg') no-repeat center center
            background-size 65%
    &--twitter
        .icon
            background url('~/static/img/twitter-black.svg') no-repeat center center
            background-size 65%
    &--instagram
        .icon
            background url('~/static/img/instagram-black.svg') no-repeat center center
            background-size 65%
    &--linkedin
        .icon
            background url('~/static/img/linkedin-black.svg') no-repeat center center
            background-size 65%
    &--whatsapp
        .icon
            background url('~/static/img/whatsapp-black.svg') no-repeat center center
            background-size 65%
    a
        width 100%
        height 100%
        pointer-events all
        display inline-block
    .overlay
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background var(--hover-color)
        -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: 2;
    .background
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        background var(--main-color)
        height 100%
        width 100%
        z-index 1
    &:hover
        transition(all .5s ease)
        .overlay
            width 400px
            height: 400px

.nav__icon--facebook
    &:hover
        .icon
            transition-delay 0.1s
            background url('~/static/img/facebook-black.svg') no-repeat center center
            background-size 65%

.nav__icon--linkedin
    &:hover
        .icon
            transition-delay 0.1s
            background url('~/static/img/linkedin-black.svg') no-repeat center center
            background-size 65%

.nav__icon--whatsapp
    &:hover
        .icon
            transition-delay 0.1s
            background url('~/static/img/whatsapp-black.svg') no-repeat center center
            background-size 65%

.nav__icon--twitter
    &:hover
        .icon
            transition-delay 0.1s
            background url('~/static/img/twitter-black.svg') no-repeat center center
            background-size 65%

.nav__share
    position absolute
    bottom calc(var(--nav-vpad) + 12px)
    left var(--nav-hpad)
    display flex
    flex-direction row
    pointer-events all
    cursor pointer
    z-index 2
    @media(min-width $laptop)
        bottom var(--nav-vpad)
        left var(--nav-hpad)
        &:hover
            color var(--hover-color)
            p
                &:before
                    background url('~/static/img/share-icon-blue.svg') no-repeat center center
    p
        margin 0
        margin-left 3em
        font-size var(--nav-font)
        @media(min-width $laptop)
            margin-left 1.5em
        &:before
            content ''
            display inline-block
            width 24px
            height 24px
            background url('~/static/img/share-icon.svg') no-repeat center center
            position absolute
            transform translate(-50%,-50%)
            left 0
            top 50%

.nav__rope
    display inline-block
    background url('~/static/img/rope-mobile-blue.svg') no-repeat bottom center
    width 100%
    height 50vh
    position absolute
    transform translate(-50%,-100%)
    left 50%
    top 0
    pointer-events none
    @media(min-width $tablet)
        background url('~/static/img/rope-blue.svg') no-repeat bottom center

.nav__rigging
    display inline-block
    background url('~/static/img/rigging-blue.svg') no-repeat center center
    background-size cover
    width 100%
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 104%
    min-height 72px
    height auto
    z-index 1
    pointer-events none
    @media(min-width $tablet)
        min-height 117px

.nav__copy
    width 100%
    height 32px
    border-radius 4px
    border 1px solid var(--main-color)
    display flex
    justify-content space-between
    align-items center
    overflow hidden
    opacity 1
    font-size var(--nav-font)
    .nav__copy-link
        padding-left 8px
        width 80%
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
    &.copying
        opacity 0.5
        transition opacity 0.5s ease

.nav__copy-icon
    cursor pointer
    pointer-events all
    height 32px
    width 32px
    display inline-block
    border-radius 4px
    position absolute
    right 0
    transform translateX(-50%)
    overflow hidden
    .icon
        transition-delay 0.1s
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        width 100%
        height 100%
        background url('~/static/img/copy-black.svg') no-repeat center center
        z-index 1
    .overlay
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background var(--hover-color)
        -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
    .background
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        background var(--main-color)
        height 100%
        width 100%
        z-index -2
    &:hover
        transition(all .5s ease)
        .icon
            background url('~/static/img/copy-black.svg') no-repeat center center
        .overlay
            width 400px
            height: 400px


.nav__copyright
    position absolute
    right var(--nav-hpad)
    bottom calc(var(--nav-vpad) + 4px)
    display flex
    flex-direction column
    opacity 1
    z-index 2
    @media(min-width $laptop)
        bottom var(--nav-vpad)
    p
        font-size var(--nav-font)
        text-transform uppercase
        margin-bottom 0
        font-weight lighter
        text-align right
    a
        pointer-events all
        cursor pointer
        &:hover
            text-decoration underline
            color var(--hover-color)

.nav__mobile-controls
    display flex
    flex-direction row
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 80%
    z-index 2

.nav__mobile-panel
    text-align center
    font-size 12px
    text-transform uppercase
    opacity 0
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 50%
    pointer-events none
    p
        margin-bottom .25em
        white-space nowrap
        &.blue
            color var(--main-color)
        &.instruction
            font-size 10px
            @media(min-width $tablet)
                font-size 14px
    &--progress
        display flex
        flex-direction row

@media(max-width $laptop)
    [data-video-state="video-start"]
        .videos
            // opacity 0.5
            transition(opacity 0.5s ease)
        .nav__mobile-panel--start
            opacity 1
    [data-video-state="video-start"][data-text-overlay="false"]
        .nav__catch--video
            display block
    [data-video-state="video-progress"]
        .nav__mobile-panel--progress
            opacity 1
        .nav__mobile-indicator--restart,
        .nav__mobile-indicator--pause,
        .nav__mobile-indicator--action
            pointer-events all
    [data-video-state="video-end"]
        .videos
            opacity 0.5
            transition(opacity 0.5s ease)
        .nav__mobile-panel--end
            opacity 1

.nav__mobile-indicator
    width 42px
    height 42px
    border 1px solid var(--main-color)
    border-radius 100%
    display inline-block
    opacity 1
    z-index 1000
    overflow hidden
    opacity 1 
    transition opacity 0.5s ease
    position relative
    margin 0 .5em
    @media(min-width $tablet)
        width 88px
        height 88px
    @media(min-width $laptop)
        display none
        

.nav__scroll-icon
    background url('~/static/img/scroll-indicator-mobile-blue.svg') no-repeat center center
    background-size contain
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 50%
    width 100%
    height 100%
    display inline-block
    transition transform 0.5s ease
    opacity 1
    &--scroll
        background url('~/static/img/scroll-icon.svg') no-repeat center center
        background-size 80%
        transform translate(-50%,-50%)
    &--watch
        background url('~/static/img/eye-icon.svg') no-repeat center center
        background-size 50%
        transform translate(-50%,-50%) scale(0)
    &--skip
        background url('~/static/img/skip-icon.svg') no-repeat center center
        background-size 40%
        transform translate(-50%,-50%)
    &--replay
        background url('~/static/img/rewind-icon-alt.svg') no-repeat center center
        background-size 50%
    &--pause
        background url('~/static/img/pause-icon.svg') no-repeat center center
        background-size 50%
        &.paused
            background url('~/static/img/play-icon.svg') no-repeat center center
            background-size 70%
    &--play
        background url('~/static/img/play-icon.svg') no-repeat center center
        background-size 50%

.nav__video-progress
    position absolute
    left 0
    top 100%
    width 100%
    height 100%
    background rgba(76, 136, 238, 0.75)
    transition background 0.5s ease
    display inline-block

.nav__border
    width 100%
    background var(--midnight)
    position absolute
    z-index 1
    left 50%
    transform translateX(-50%)
    &--top
        height 115px
        top 0
        @media(min-width $large-mobile)
            height 0
    &--bottom 
        height 89px
        bottom 0
        opacity 0
        @media(min-width $laptop)
            height 10vh
    @media(min-width $laptop)
        opacity 0

[data-text-overlay="true"]
    .nav__mobile-indicator
        opacity 0
        transition opacity 0.5s ease
    .modal--scene
        opacity 0
    .nav__share
        opacity 0
    .nav__copyright
        opacity 0
    .nav__progress--mobile
        opacity 0
    .nav__mobile-panel
        opacity 0
    @media(min-width $large-mobile)
        .nav__logo
            opacity 0.3


.is-scrolled
    .nav__accordion
        visibility visible