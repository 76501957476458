// ========================================================
// THEME VARIABLES & OPTIONS
// ========================================================

/* OPTIONS */
fixednav = true;
transparentnav = false;

/* SCREEN VARIABLES */
$large-mobile = 500px
$tablet = 768px
$laptop = 1024px
$desktop = 1240px
$large-desktop = 1600px

:root
    
    // COLOURS
    --white #FFFFFF
    --black #0E0A19
    --grey #D8D8D8
    --spotsize 5%
    --spotx 50%
    --spoty 50%
    // PALETTE
    --sapphire rgba(76, 136, 238, 1)
    --cobalt rgba(61, 76, 233, 1)
    --oxford rgba(22, 18, 97, 1)
    --midnight rgba(14, 10, 25, 1)
    --blonde rgba(254, 249, 172, 1)
    --butter rgba(252, 237, 12, 1)
    --marigold rgba(255, 174, 1, 1)
    --tiger rgba(255, 118, 0, 1)
    --cream rgba(253, 247, 229, 1)
    --flamingo rgba(246, 178, 184, 1)
    --rouge rgba(255, 110, 123, 1)
    --strawberry rgba(255, 49, 53, 1)
    --coral rgba(245, 92, 95, 1)
    --rasberry rgba(197, 29, 96, 1)
    --rosewood rgba(121, 52, 52, 1)
    --wine rgba(77, 14, 39, 1)

    
    // COLOUR FUNCTIONS
    --color-dark var(--black)
    --color-light var(--white)
    --color-primary var(--black)
    --color-secondary var(--white)
    --color-success #36ED9C
    --color-error #FF3154

    --main-color var(--sapphire)
    --hover-color var(--sapphire)
    
    --text-color var(--cream)
    --link-color inherit
    --link-hover var(--red)

    // PAGE
    --background var(--midnight)
    
    // BUTTONS
    --button-bg var(--black)
    --button-text var(--color-light)
    --button-padding 12px 32px
    --button-radius 8px
    
    // GRID
    --unit 1rem
    --content-width calc( 100% - 2rem )
    --vpad 24px
    --hpad 24px
    --nav-height 80px
    --single-vpad 24px
    --nav-vpad 20px
    --nav-hpad 20px

    @media( min-width: $tablet )
        --unit 2rem
        --content-width 600px
        --nav-height 100px
        --nav-vpad 40px
        --nav-hpad 40px

    @media( min-width: $laptop )
        --unit 4rem
        --content-width 700px
        --vpad 88px
        --single-vpad 120px
        --nav-vpad 40px
        --nav-hpad 56px

    @media( min-width: $desktop )
        --unit 4rem
        --content-width 940px
        --nav-height 120px
        --vpad 104px
        --single-vpad 200px

    @media( min-width: $large-desktop )
        --unit 4rem
        --content-width 1240px


.body--dark, .nav--open
    --color-primary var(--white)

[data-active-color="blue"]
    --main-color var(--sapphire)
    --hover-color var(--cobalt)
    .main
        background var(--midnight)
    .animation-red
        opacity 0
    .animation-blue
        opacity 1

.transition
    .main, .animation
        background var(--midnight)!important
    .text
        opacity 0!important
    .nav__logo
        background url("~/static/img/logo-cream.svg") no-repeat center center!important
        background-size contain!important
    .nav__share, .nav__copyright
        color var(--cream)!important
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon.svg') no-repeat center center!important
    .nav__cta
        .background
            background var(--cobalt)!important
    .modals
        opacity 0!important

[data-active-color="red"]
    --main-color var(--strawberry)
    --hover-color var(--tiger)
    .nav__share
        &:hover
            p
                color var(--hover-color)
                &:before
                    background url('~/static/img/share-orange.svg') no-repeat center center
    .cursor__arrows
        background url('~/static/img/scroll-arrows-red.svg') no-repeat center center
    .cursor__item
        &:before
            content ''
            background url('~/static/img/eye-open-red.svg') no-repeat center center
            background-size contain
        &:hover
                &:before
                    content ''
                    background url('~/static/img/eye-closed-red.svg') no-repeat center center
                    background-size contain
    .nav__icon
        &--facebook
            .icon
                background url('~/static/img/facebook-red.svg') no-repeat center center
                background-size 65%
        &--twitter
            .icon
                background url('~/static/img/twitter-red.svg') no-repeat center center
                background-size 65%
        &--instagram
            .icon
                background url('~/static/img/ig-red.svg') no-repeat center center
                background-size 65%
        &--linkedin
            .icon
                background url('~/static/img/linkedin-red.svg') no-repeat center center
                background-size 65%
        &--whatsapp
            .icon
                background url('~/static/img/whatsapp-red.svg') no-repeat center center
                background-size 65%
    .nav__rigging
        background url('~/static/img/rigging-red.svg') no-repeat center center
        background-size cover
    .nav__rope
        background url('~/static/img/rope-mobile-red.svg') no-repeat bottom center
        @media(min-width $tablet)
            background url('~/static/img/rope-red.svg') no-repeat bottom center
    .nav__progress--mobile
        background rgba(255, 49, 53, 0.2)
    .nav__item.active
        .nav__title
            @media(max-width $laptop)
                background rgba(255, 49, 53, 0.3)
    .nav__scroll-indicator
        background url('~/static/img/scroll-indicator-mobile-red.svg') no-repeat center center
    .animation-red
        opacity 1
    .animation-blue 
        opacity 0


// .pin-spacer
//     @media(max-width $laptop)
//         height 100vh!important
//         padding 0!important