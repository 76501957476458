//========================================================
// GLOBAL STYLES
//========================================================

:root
	font-size 16px
	@media(min-width: $tablet)
		font-size 20px

*,html,body
	box-sizing border-box

*:focus
	outline none

html
	width 100%
	height auto
	min-height 100%
	box-sizing border-box
	background-color var(--background)
	// scroll-behavior smooth

body
	width 100%
	height 100vh
	// height calc(var(--vh, 1vh) * 100)
	overflow-y scroll
	margin 0
	padding 0
	position relative
	background-color var(--background)
	// overflow hidden
	
// .loading
// 	visibility hidden

// main
// 	display block
// 	margin-left auto
// 	margin-right auto
// 	width 100%

.blink_me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
	opacity: 0;
  }
}

.spacer
	height 300vh
	@media(min-width $laptop)
		height 100vh

.button
	pointer-events all
	text-decoration none
	font-weight lighter
	font-size 10px
	line-height 10px
	padding 1.2em 1.4em
	padding-top 1.5em
	font-weight 400
	border-radius 4px
	text-transform uppercase
	overflow hidden
	position relative
	@media(min-width $laptop)
		padding-top 1.2em
	@media(min-width $tablet)
		font-size 12px
	.overlay
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-radius: 50%;
		background var(--hover-color)
		-webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
		transform: translate(-50%, -50%);
		z-index: -1;
	.background
		position: absolute;
		display: block;
		width: 150%
		height: 150%
		background var(--main-color)
		transform: translate(-50%, -50%);
		top 50%
		left 50%
		z-index: -2;
	&:hover
		transition(all .5s ease)
		.overlay
			width 400px
			height: 400px

.button--hamburger
	pointer-events all
	border-radius 4px
	width 40px 
	height 40px
	background url('~/static/img/hamburger-black.svg') no-repeat center center, var(--main-color)
	margin-left 24px
	&.is-active
		background url('~/static/img/cross-black.svg') no-repeat center center, var(--main-color)

// ADD THIS CLASS TO ANY OBJECT THAT NEEDS TO HAVE A MAX WIDTH
.contain
	max-width var(--content-width)
	margin-left auto
	margin-right auto

// THIS CLASS MAKES OBJECTS IN MAX-WIDTH CONTAINERS BREAK OUT AND FILL SCREEN
.max-width,
.alignfull
	margin-left calc(-50vw + 50%)
	margin-right calc(-50vw + 50%)
	max-width 100vw
	width 100vw

.alignwide
	margin-left calc(-10vw)
	margin-right calc(-10vw)
	max-width 100vw

// .loading
// 	visibility hidden

img
	max-width 100%
	height auto

//
//
// FLEX STYLES
//
// These styles are just for rows. For columns use flex_direction(column) mixin
// For adding these flexbox styles to other classes we can use @extend
// i.e. @extend .w-10
//
.flex
	flex_box()
	flex_wrap()

for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	.w-{num}
		width 100%
for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	// SEE VARIABLES.STYL FOR SETTING BREAKPOINTS
	// LARGE MOBILE = w-{num}
	@media(min-width: $large-mobile)
		.w-{num}
			width (num)%
for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	// TABLET = w-{num}-tb
	@media(min-width: $tablet)
		.w-{num}-tb
			width (num)%
for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	// LAPTOP = w-{num}-lp
	@media(min-width: $laptop)
		.w-{num}-lp
			width (num)%
for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	// DESKTOP = w-{num}-dp
	@media(min-width: $desktop)
		.w-{num}-dp
			width (num)%
for num in (10 20 25 30 33 34 40 50 60 70 75 80 90 100)
	// LARGE DESKTOP = w-{num}-lp
	@media(min-width: $large-desktop)
		.w-{num}-ld
			width (num)%

.flex__row
	flex_box(inherit)
	flex_wrap(inherit)
	flex_direction(inherit)
	justify_content(inherit)
	width 100%

.flex--no-wrap
	flex_wrap(nowrap)

.flex--reverse
	flex_direction(row-reverse)

.flex--justified
	justify_content(space-between)

.flex--justified-left
	&:after
		content ''
		display block
		margin-right auto
	.flex__row
		&:after
			content ''
			display block
			margin-right auto

.flex--aligncenter
	align_items(center)

.flex--alignbottom
	align_items(flex-end)


.scroll-down
	color inherit
	position relative
	text-decoration none
	font-size 0.8rem
	cursor pointer
	text-align center
	font-weight 600
	margin 2rem auto
	display block
	&:after
		content ''
		transition(all 0.5s ease)
		display block
		height 36px
		width 36px
		font-size 2.3em
		text-align center
		position absolute
		left 50%
		top 130%
		background url('~/static/img/icon-chevron-down.svg') no-repeat center center transparent
		background-size contain
		transform( translateX(-50%) )

// .noselect
// 	-webkit-touch-callout none
// 	-webkit-user-select none
// 	-khtml-user-select none
// 	-moz-user-select none
// 	-ms-user-select none
// 	user-select none

p.icon
	background #FDEADC
	border-radius(50%)
	width 60px
	height 60px
	padding 20px
	position relative
	display block
	overflow hidden
	@media(min-width:768px)
		width 90px
		height 90px
	img
		position absolute
		top 50%
		left 50%
		height 60% !important
		width auto !important
		padding 5px
		object-fit contain
		margin 0 !important
		transform(translate(-50%,-50%))

// USEFUL CLASSES
.no-m
	margin 0

br {
  font-family: initial !important; /* Fix error on Edge (br render a Latin space character) */
}