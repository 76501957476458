// ========================================================
// TYPOGRAPHY
// =========================================================


@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: "Akzidenz-Grotesk Extended";
  src: url("~/static/fonts/akzidgrobqex-webfont.woff2") format("woff2"), 
  	url("~/static/fonts/akzidgrobqex-webfont.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Akzidenz-Grotesk Extended Medium";
  src: url("~/static/fonts/akzidgrobqfmdex-webfont.woff2") format("woff2"), 
  	url("~/static/fonts/akzidgrobqfmdex-webfont.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Akzidenz-Grotesk Extended Bold";
  src: url("~/static/fonts/akzidgrobqbolex-webfont.woff2") format("woff2"), 
  	url("~/static/fonts/akzidgrobqbolex-webfont.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Akzidenz-Grotesk";
  src: url("~/static/fonts/akzidgrobqreg.woff2") format("woff2"), 
  	url("~/static/fonts/akzidgrobqreg.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Akzidenz-Grotesk Bold";
  src: url("~/static/fonts/akzidgrobqbol.woff2") format("woff2"), 
  	url("~/static/fonts/akzidgrobqbol.woff") format("woff");
  font-style: normal;
}


:root
	
	--primaryFont 'Akzidenz-Grotesk Extended', sans-serif;
	--boldFont 'Akzidenz-Grotesk Extended Bold', sans-serif;
	--medFont 'Akzidenz-Grotesk Extended Medium', sans-serif;
	--regFont "Akzidenz-Grotesk", sans-serif;
	--regBoldFont"Akzidenz-Grotesk Bold", sans-serif;

	// FONT SIZING
	--fs-h1 32px
	--fs-h2 28px
	--fs-h2-alt 36px
	--fs-h3 18px
	--fs-h3-alt 40px
	--fs-h4-alt 48px
	--fs-h4 26px
	--fs-h5 20px
	--fs-b-large 18px
	--fs-b-med 16px
	--fs-b-small 14px
	--fs-button 14px
	--nav-font 8px

	@media(min-width:$tablet)
		--fs-h1 56px
		--fs-h2 108px
		--fs-h2-alt 122px
		--fs-h3 48px
		--fs-h3-alt 62px
		--fs-h4 46px
		--fs-h4-alt 54px
		--fs-h5 32px
		--fs-b-large 22px
		--fs-b-med 20px
		--fs-b-small 18px
		--fs-button 18px
		--nav-font 10px

	@media(min-width:$laptop)
		--fs-h2 108px
		--fs-h2-alt 122px
		--fs-h3 48px
		--fs-h3-alt 62px
		--fs-h4 36px
		--fs-h4-alt 44px
		--fs-h5 32px
		--fs-b-large 22px
		--fs-b-med 20px
		--fs-b-small 18px
		--fs-button 18px
		--nav-font 10px

	@media(min-width $desktop)
		--fs-h2 128px
		--fs-h2-alt 142px

	@media(min-width $large-desktop)
		--fs-h1 56px
		--nav-font 12px
		--fs-h2 128px
		--fs-h2-alt 142px
		--fs-h3 58px
		--fs-h3-alt 72px
		--fs-h4 56px
		--fs-h4-alt 64px
		--fs-h5 32px
		--fs-b-large 22px
		--fs-b-med 20px
		--fs-b-small 18px
		--fs-button 18px

body
	font-family var(--primaryFont)
	font-size var(--fs-b-med)
	line-height 1.4em
	font-weight 400
	color var(--text-color)
	optimise()

h1, h2, h3, h4, button
	font-family var(--primaryFont)

p,li,blockquote,input,textarea
	font-size inherit

p, li
	margin 0 0 1.25rem 0
	color inherit
	font-size var(--fs-b-med)
	line-height 1.8em

h1,.h1
	font-size var(--fs-h1)

h2,.h2
	font-size var(--fs-h2)

h3,.h3
	font-size var(--fs-h3)

h4,.h4
	font-size var(--fs-h4)

h5, .h5
	font-size var(--fs-h5)

h6,.h6
	font-size var(--fs-b)

// ALL LINE-HEIGHTS SHOULD BE IN EMs

h1, h2, h3,
.h1, .h2, .h3
	color inherit
	font-weight 800
	line-height 1em
	letter-spacing 0
	text-align inherit
	margin 0
	padding 0
	optimise()

h1, .h1
	line-height .8em

h3
	line-height .9em
	padding-bottom 32px

h4, h5
.h4, .h5
	font-weight 400
	color inherit
	letter-spacing 0
	line-height 1.3em
	text-align inherit
	margin 0
	optimise()

h5, .h5
	line-height 1.5em

p
	line-height 1.5em

a
	color inherit
	outline none
	font-weight inherit
	text-decoration underline
	&:visited
		color inherit
		outline none
	&:hover,
	&:focus
		text-decoration none
		outline none
		color inherit

button
	border none
	border-radius(0)

b, strong
	display inline
	font-size inherit
	line-height inherit
	font-weight 800

.alt 
	font-family var(--altFont)

h1.alt
	font-size var(--fs-h1-alt)
	font-weight 400

h2.alt
	font-size var(--fs-h2-alt)
	font-weight 400
h3.alt
	font-size var(--fs-h3-alt)
	font-weight 400

span.alt
	font-weight 400
h3
	strong
		font-size var(--fs-h3)
		font-weight 800
		font-family var(--primaryFont)
.strong
	font-weight 600

.caps
	text-transform uppercase
	letter-spacing 0.1em

.subtitle
	font-family var(--primaryFont)
	text-transform uppercase
	letter-spacing 0.1em
	font-weight 600
	font-size var(--fs-b-small)

.center
	text-align center

.white
	color var(--white)
	p,ul
		color inherit

i,em,mark
	display inline

mark
	color var(--text-color)
	background var(--color-primary)
	padding 0 .3rem

u
	display inline

ul,ol
	margin 0
	padding 0
	color inherit
	margin-left 1.2em

li
	font-size var(--fs-b)
	line-height 1.2em
	margin 1rem 0


blockquote
	width 100%
	max-width 1000px
	margin 1.25rem auto
	padding-left 0
	position relative
	font-family var(--headingFont)
	font-weight 600
	font-style normal
	// font-size 1.7rem
	line-height 1.3em
	p
		max-width 1000px
		font-family var(--headingFont)
		font-weight 600
		font-style normal
		font-size var(--fs-h4)
		line-height 1.3em
	cite
		font-style normal
		font-size var(--fs-small)
		line-height 1.375em


label,
.label,
input,
textarea,
select
	font-family var(--textFont)

