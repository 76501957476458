.cursor
	width 88px
	height 88px
	position fixed
	top 0
	left 0
	border-radius 50%
	border 2px solid var(--main-color)
	pointer-events none
	z-index 20
	overflow hidden
	display none
	z-index 10000
	@media(min-width $laptop)
		display block
	&.small
		.cursor__arrows, .cursor__scroll-text, .cursor__text
			opacity 0
	&.up
		.cursor__arrows
			background url("~/static/img/scroll-up.svg") no-repeat center center 
	&.down
		.cursor__arrows
			background url("~/static/img/scroll-down.svg") no-repeat center center 
		
.cursor__arrows
	display inline-block
	background url("~/static/img/scroll-normal.svg") no-repeat center center 
	width 100%
	height 100%
	position absolute
	transform translate(-50%,-50%)
	left 50%
	top 50%

.cursor__text
	width 100%
	line-height 40px
	font-size 12px
	height 40px
	overflow hidden
	position absolute
	transform translateX(-50%) translateY(-50%);
	left 50%
	top 50%
	transition all 0s ease
	text-align: center;

.cursor__scroll-text
	display: block;
	position: absolute;
	text-align: center;
	top: 0;
	left: 50%;
	width: 100%;
	transform: translateX(-50%);
	position absolute
	color var(--cream)
	margin 0
	padding 0
	text-transform uppercase
	transition all 0s ease

.cursor__media
	width 100%
	height 100%
	z-index 5
	position absolute
	top 50%
	left 50%
	transform translate(-50%,-50%)
	border-radius 50%
	overflow hidden
	background transparent
	img
		opacity 0
		position absolute
		top 50%
		left 50%
		object-fit cover
		width 100%
		height 100%
		transform translate(-50%,-50%)

.cursor__item
	display inline-block
	color var(--main-color)
	font-family var(--regBoldFont)
	white-space nowrap
	-webkit-touch-callout none
	-webkit-user-select none
	-khtml-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	padding-right .25em
	pointer-events all
	@media(min-width $laptop)
		padding-right 0
		position relative
	&.wrap
		white-space normal
	&.no-margin
		padding-right 0
	&:before
		content ''
		display inline-block
		background url('~/static/img/eye-open.svg') no-repeat center center
		background-size contain
		width 17.4px
		height 12px
		vertical-align middle
		margin-right .25em
		height .75em
		width calc(.75em * 1.45)
	div
		pointer-events none
	img, .cursor__img-wrapper
		--rotation 4deg
		--offset -50%
		--width 254px
		--height calc(var(--width) * 0.72)
		position absolute
		transform translate(-50%,-50%)
		left 50%
		top -120px
		z-index 100
		width var(--width)
		height var(--height)
		pointer-events none
		min-width var(--width)
		min-height var(--height)
		object-fit cover
		@media(min-width $tablet)
			--width 400px
			top -170px
		@media(min-width $laptop)
			--width 300px
			top -160px
			transform translate(-50%,-50%)
		@media(min-width $desktop)
			--width 400px
			top -200px
	img
		top 50%!important
		left 50%!important
		transform translate(-50%,-50%) scale(0) rotate(var(--rotation))
		transition transform .6s ease!important
	&.portrait
		img, .cursor__img-wrapper
			width var(--height)
			height var(--width)
			min-width var(--height)
			min-height var(--width)
		.cursor__img-wrapper
			top -145px
			@media(min-width $tablet)
				top -250px
	&.portrait.top
		.cursor__img-wrapper
			top 190px
			@media(min-width $tablet)
				top 280px   
	&.active
		color var(--main-color)
		text-shadow none
		img
			transform translate(-50%,-50%) scale(1) rotate(var(--rotation))
			transition transform .6s ease!important
		&:before
			background url('~/static/img/eye-closed.svg') no-repeat center center
			background-size contain
	&.top
		.cursor__img-wrapper
			top 135px
			@media(min-width $tablet)
				top 200px
			@media(min-width $desktop)
				top 250px

.cursor__line
	position relative

.cursor__img-wrapper
	display inline
	position absolute