.modal--open.is-scrolled
    main
        pointer-events none
        opacity 0
        section
            pointer-events none
    .animation
        opacity 0.2
    .nav__copyright
        color var(--main-color)
    .nav__share
        color var(--main-color)
    .nav
        pointer-events all
    .nav__catch
        display block
        pointer-events all
    .nav__accordion, .nav__item
        pointer-events none
    .nav__mobile-controls
        pointer-events none
        opacity 0
    .nav__accordion
        @media(min-width $laptop)
            opacity 0.1


.modal--open[data-active-color="blue"]
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-blue.svg') no-repeat center center
    .nav__logo
        background url("~/static/img/logo-blue.svg") no-repeat center center
        background-size contain

.modal--open[data-active-color="red"]
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-red.svg') no-repeat center center
    .nav__logo
        background url("~/static/img/logo-red.svg") no-repeat center center
        background-size contain

.modals
    height 100vh
    // height calc(var(--vh, 1vh) * 100)
    width 100%
    position fixed
    top 0
    left 0
    z-index 100
    pointer-events none

.modal
    position absolute
    transform translate(-50%,-50%)
    left 50%
    top 50%
    color var(--black)
    --button-width 32px
    pointer-events all
    max-width 90%
    z-index 1000
    border-radius 4px
    width 100%
    @media(min-width $tablet)
        max-width 452px
    h3
        font-size 16px
        text-transform uppercase
        white-space nowrap
        font-family var(--boldFont)
        margin-right auto
        padding-top 8px
        margin-bottom 0
        padding-bottom 12px
        @media(min-width $tablet)
            font-size 22px
    p
        font-size 12px
        width 90%
        padding-bottom 0
        margin-bottom 0
        @media(min-width $tablet)
            font-size 14px
    strong
        font-family var(--boldFont)
    h4
        font-size 12px
        font-family var(--boldFont)
        padding-bottom 8px
        @media(min-width $tablet)
            font-size 14px

.modal__close
    width 32px
    height 32px
    display inline-block
    cursor pointer
    pointer-events all
    border-radius 4px
    position absolute
    top 16px
    right 16px
    overflow hidden
    .icon
        transition-delay 0.1s
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        width 100%
        height 100%
        background url('~/static/img/cross-black.svg') no-repeat center center
        background-size 42%
        z-index 3
    .overlay
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background var(--hover-color)
        -webkit-transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: 2;
    .background
        position absolute
        transform translate(-50%,-50%)
        left 50%
        top 50%
        background var(--main-color)
        height 100%
        width 100%
        z-index 1
    &:hover
        transition(all .5s ease)
        .overlay
            width 400px
            height: 400px
    @media(min-width $tablet)
        top 24px
        width 40px
        height 40px


.modal__inner
    position relative
    z-index 10
    width 100%
    background var(--cream)
    height 100%
    display flex
    flex-direction column
    padding 16px
    border-radius 4px
    @media(min-width $tablet)
        padding 24px

.modal--end
    @media(min-width $tablet)
        min-width 500px

.modal__text
    @media(min-width $tablet)
        width 70%
    h3
        white-space normal
    
.modal__buttons
    margin-top 1.25em
    margin-bottom .5em
    display flex
    flex-direction column
    @media(min-width $tablet)
        flex-direction row

.modal--share__wrapper, .modal--end__wrapper
    width 100vw
    height 100vh
    position absolute
    top 0
    left 0
    z-index 100
    pointer-events none

.button--learn, .button--encore
    position relative
    // display inline-block
    width 130px
    min-height 43px
    display flex
    justify-content center
    align-items center
    @media(min-width $tablet)
        display flex
        height 43px
        width auto
        margin-right auto
    .icon
        background url('~/static/img/encore-icon.svg') no-repeat center center
        display inline-block
        height 1em
        width 1em
        margin-right .5em
        background-size contain

.button--learn
    overflow hidden
    position relative
    margin-bottom 1.5em
    @media(min-width $tablet)
        margin-right 1em

.button--encore
    border 1px solid var(--black)
    .background
        background var(--cream)

.modal--scene
    position fixed
    transform none
    top auto
    left 50%
    max-width 280px
    bottom calc(var(--nav-vpad) * 3.5)
    align-items stretch
    transform translateX(-50%) translateY(400%)
    p
        width 100%
        &:after
            @media(max-width $laptop)
                content ' Tap to continue'
                font-family var(--boldFont)
                font-weight 800
        @media(min-width $tablet)
            padding-right 1em
    @media(min-width $tablet)
        max-width 498px
        left auto
        right var(--nav-hpad)
        transform translateY(300%) rotate(4deg)
        bottom calc(var(--nav-vpad))
    @media(min-width $laptop)
        max-width 32.5vw
    @media(min-width $large-desktop)
        max-width 30vw

.modal--scene
    .modal__inner
        @media(min-width $tablet)
            display flex
            flex-direction row
            min-height 150px
        p
            margin auto
            font-weight 400
            font-family var(--primaryFont)
        strong
            font-family var(--boldFont)

.modal__section
    opacity 0

.modal__illustration
    display none
    width 100%
    height auto
    flex-basis 40%
    position relative
    svg
        position absolute
        transform translate(-50%,-50%)!important
        left 50%
        top 50%
    @media(min-width $tablet)
        display inline-block

.modal__end-illustration
    position absolute
    bottom 0
    right 0
    background url('~/static/img/end-modal-illo.svg') no-repeat center center
    background-size contain
    height 158px
    width 113px
    @media(min-width $tablet)
        background-size cover
        height 234px
        width 167px

[data-active-modal="one"]
    [data-modal="one"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-modal="two"]
    [data-modal="two"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-modal="three"]
    [data-modal="three"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-modal="four"]
    [data-modal="four"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-modal="five"]
    [data-modal="five"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-modal="six"]
    [data-modal="six"]
        transform translateX(-50%) translateY(0) rotate(0)
        transition transform 0.5s ease
        @media(min-width $tablet)
            transform translateY(0) rotate(0)

[data-active-scene="setting-the-stage"]
    [data-scene-modal="setting-the-stage"]
        opacity 1

[data-active-scene="the-auditorium"]
    [data-scene-modal="the-auditorium"]
        opacity 1
    
[data-active-scene="the-stage"]
    [data-scene-modal="the-stage"]
        opacity 1

[data-active-scene="lighting"]
    [data-scene-modal="lighting"]
        opacity 1

[data-active-scene="offstage"]
    [data-scene-modal="offstage"]
        opacity 1

[data-active-scene="cast-and-creatives"]
    [data-scene-modal="cast-and-creatives"]
        opacity 1

[data-active-scene="the-performance"]
    [data-scene-modal="the-performance"]
        opacity 1