.videos
    height 100vh
    width 100vw
    overflow hidden
    position fixed
    top 0
    left 50%
    transform translateX(-50%) translateZ(0);
    z-index 5
    pointer-events none
    display block
    opacity 1
    transition(opacity 1s ease)
    @media(min-width $laptop)
        display none
        width 100vw
    video
        opacity 0
        object-fit contain
        position absolute
        top 0
        left 0
        width 100%
        height 100%

[data-active-scene="setting-the-stage"]
    [data-scene-video="setting-the-stage"]
        opacity 1
        transition(opacity 1s ease)

[data-active-scene="front-of-house"]
    [data-scene-video="front-of-house"]
        opacity 1
        transition(opacity 1s ease)

[data-active-scene="the-auditorium"]
    [data-scene-video="the-auditorium"]
        opacity 1
        transition(opacity 1s ease)

[data-active-scene="the-stage"]
    [data-scene-video="the-stage"]
        opacity 1
        transition(opacity 1s ease)

[data-active-scene="lighting"]
    [data-scene-video="lighting"]
        opacity 1
        transition(opacity 1s ease)


[data-active-scene="offstage"]
    [data-scene-video="offstage"]
        opacity 1
        transition(opacity 1s ease)


[data-active-scene="cast-and-creatives"]
    [data-scene-video="cast-and-creatives"]
        opacity 1
        transition(opacity 1s ease)


[data-active-scene="the-performance"]
    [data-scene-video="the-performance"]
        opacity 1
        transition(opacity 1s ease)


[data-active-scene="back-of-house"]
    [data-scene-video="back-of-house"]
        opacity 1
        transition(opacity 1s ease)

[data-active-scene="coming-soon"]
    [data-scene-video="coming-soon"]
        opacity 1
        transition(opacity 1s ease)
