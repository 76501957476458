[data-active-animation="false"]
    .nav__share, .nav__copyright
        color var(--main-color)
    #background-animation
        opacity 0.3


[data-active-animation="false"][data-active-color="red"]
    .nav__logo
        background url("~/static/img/logo-red.svg") no-repeat center center
        background-size contain
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-red.svg') no-repeat center center
        &:hover
            p
                color var(--hover-color)
                &:before
                    background url('~/static/img/share-orange.svg') no-repeat center center

[data-active-animation="false"][data-active-color="blue"]
    .nav__share
        p
            &:before
                background url('~/static/img/share-icon-blue.svg') no-repeat center center
        &:hover
            p
                color var(--hover-color)
                &:before
                    background url('~/static/img/share-blue.svg') no-repeat center center


.main
    background var(--background)
.intro
    position fixed
    z-index 7
    width 100%
    height 100%
    opacity 0
    pointer-events none
    img
        position absolute
        top 50%
        left 50%
        transform translate(-50%,-50%)
        width 100%
        height 100%
    .light
        background url('~/static/img/light-intro.svg') no-repeat center center
        background-size contain
        clip-path circle(var(--spotsize) at var(--spotx) var(--spoty))

.section
    position relative
    overflow-y scroll
    overflow-x hidden
    pointer-events all
    z-index 6

.animation
    height 100vh
    // height calc(var(--vh, 1vh) * 100)
    width 120vw
    overflow hidden
    position fixed
    top 0
    left 50%
    transform translateX(-50%)
    z-index 5
    pointer-events none
    display none
    opacity 0.5
    transition(opacity 0.5s ease)
    @media(min-width $laptop)
        display block
        width 100vw
        height 90vh
        transform translate(-50%,-50%)
        left 50%
        top 50%
    svg
        opacity 0
        position absolute
        top 0
        left 0
        width 100%
        height 100%

.text
    width 80%
    margin auto
    position relative
    z-index 10
    @media(min-width $large-mobile)
        width 90%
        padding-right 10%
    @media(min-width $laptop)
        width 60%
        padding-right 0
    @media(min-width $large-desktop)
        width 68%
    h5
        color var(--main-color)
        text-transform uppercase
        padding 40px 0
        font-size 10px
        @media(min-width $tablet)
            font-size 12px
        @media(min-width $large-desktop)
            font-size 20px
    h1
        padding-bottom 0.5em
        font-weight 700
        font-family var(--boldFont)
        font-size 8vw
        text-transform uppercase
        line-height 1em
        @media(min-width $tablet)
            font-size 56px
        @media(min-width $laptop)
            font-size 90px
        @media(min-width $large-desktop)
            font-size 112px
    h3, strong
        text-align left
        position relative
        line-height 1.35em
        font-family var(--regFont)
        font-size 16px
        font-weight 400
        @media(min-width $tablet)
            font-size 28px
        @media(min-width $laptop)
            font-size 40px
        @media(min-width $large-desktop)
            font-size 48px
    strong
        color var(--main-color)
        font-family var(--regBoldFont)
    .hidden
        opacity 0
        transition opacity 0.5s ease
    .in-view
        opacity 1
        transition opacity 0.5s ease


.scroll-indicator
    position fixed
    top 40px
    left 40px
    height 40px
    width 40px
    background var(--white)
    // border-radius 100%

[data-scene="prologue"]
    .scroll-indicator
        background var(--red)
        z-index 10

#background-animation
    opacity 0
    z-index 0
